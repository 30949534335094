.about-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.about-logo {
	display: flex;
	position: fixed;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.about-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin-top: 120px;
}

.about-main {
	display: flex;
}

.about-right-side {
	flex-basis: 900px;
}

.about-title {
	width: 100% !important;
}

.about-subtitle {
	width: 90% !important;
}

.about-left-side {
	display: flex;
	flex-direction: column;
	flex-basis: 300px;
}

.about-image-container {
	width: 370px;
}

.about-image-wrapper {
	display: inline-block;
	overflow: hidden;
	width: 100%;
	border-radius: 10%;
	transform: rotate(2.5deg);
}

.about-image-wrapper img {
	display: block;
	width: 100%;
}

.about-socials {
	padding-top: 50px;
	padding-bottom: 50px;
}

.about-socials-mobile {
	display: none;
}

.about-works {
	padding-top: 50px;
	width: 80%;
	margin: 0 -5px;
}

.about-skills-mobile {
	display: none;
}

.about-skills-container {
	padding-top: 50px;
}

.about-skills-category {
	padding-top: 10px;
}

.about-skills-category-subtitle {
	color: var(--secondary-color);
}

.about-skills {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	padding-top: 12px;
}

.about-skills-title {
	font-size: 30px;
	font-weight: 700;
	color: var(--secondary-color);
	font-family: var(--secondary-font);
}

.about-skill {
	padding-right: 5px;
}

@media (max-width: 1024px) {
	.about-container {
		margin-top: 90px;
	}
	.about-logo {
		z-index: 100;
	}

	.about-main {
		flex-direction: column;
	}

	.about-subtitle {
		padding-top: 5px !important;
	}

	.about-left-side {
		flex-direction: unset;
		justify-content: space-around;
		/* flex-basis: 100%; */
		/* order: 1; */
	}

	.about-right-side {
		padding: 5%;
		flex-basis: 100%;
		order: 2;
	}

	.about-image-container {
		width: 58.5%;
		/* padding-bottom: 40px; */
	}

	.about-works {
		width: 100%;
	}

	.about-image-wrapper {
		transform: rotate(0deg);
	}

	.about-socials {
		display: none;
	}

	.about-skills-container {
		display: none;
	}

	.about-skills-mobile {
		display: block;
		padding: 30px 2% 0;
	}

	.about-skills-categories {
		max-width: 480px;
	}

	.about-skills {
		padding-left: 0;
	}

	.about-socials-mobile {
		padding-left: 10px;
		padding-top: 40px;
		display: flex;
	}
}

@media (max-width: 600px) {
	.about-image-container {
		width: 90%;
		padding-bottom: 20px;
	}

	.about-title {
		padding-left: 15px;
		padding-bottom: 15px;
		/* display: none; */
	}

	.about-subtitle {
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
		margin-top: 0 !important;
	}

	.about-left-side {
		flex-direction: unset;
		justify-content: space-around;
	}
}
